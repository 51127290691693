/*
MD Fardeen Ehsan Shawon
fardeen.es7@gmail.com
*/
import React from "react";

export default function AuthTemplate() {
  return (
    <div className="hidden h-full w-full lg:block">
      <div className="h-full w-full bg-customBlue p-16">AUTH PAGE</div>
    </div>
  );
}
