/*
MD Fardeen Ehsan Shawon
fardeen.es7@gmail.com
*/

export default function Home() {
  return (
    <div>
      Landing Page
      <br />
      <a href="/dash">Go to dashboard</a>
    </div>
  );
}
